<nav #navbar class="navbar navbar-expand-lg navbar-transparent  navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button mat-raised-button (click)="minimizeSidebar()" class="btn btn-just-icon btn-white btn-fab btn-round">
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button>
      </div>
      <a *ngIf="listTitles" class="navbar-brand" href="{{getPath()}}"> {{getTitle()}}</a>
    </div>
    <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <!-- <form class="navbar-form">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </form> -->
      <ul class="navbar-nav">
        <!-- <li class="nav-item">
          <a class="nav-link" href="#pablo">
            <i class="material-icons">dashboard</i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li> -->
        <!-- <li class="nav-item dropdown">
          <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">notifications</i>
            <span class="notification">5</span>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="#">Mike John responded to your email</a>
            <a class="dropdown-item" href="#">You have 5 new tasks</a>
            <a class="dropdown-item" href="#">You're now friend with Andrew</a>
            <a class="dropdown-item" href="#">Another Notification</a>
            <a class="dropdown-item" href="#">Another One</a>
          </div>
        </li> -->
        <div class="dropdown" >
          <button class="btn btn-secondary" type="button" id="menu" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="material-icons"> more_vert </i>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li class="nav-item">
              <a class="nav-link" style="cursor:pointer;" (click)="logoutAction()">
                <i class="material-icons">power_settings_new</i>
                &nbsp; Logout
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" style="cursor:pointer;" (click)="changePass()">
                <i class="material-icons">settings</i>
                &nbsp; Change Password
              </a>
            </li>
          </div>
        </div>

      </ul>
    </div>
  </div>
</nav>