import { Routes } from '@angular/router';
import { AuthGuard } from './core/guard/Auth.guard';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { NotFoundComponent } from './not-found/not-found.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                canActivateChild: [AuthGuard],
                path: '',
                loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
            }]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'users',
            loadChildren: () => import('./user-pages/user-pages.module').then(m => m.UserPagesModule)
        }]
    },
    {
        path: '404',
        component: NotFoundComponent
    },
    {
        path: '**',
        redirectTo: '404',
    },
];
