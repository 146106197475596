import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import PerfectScrollbar from "perfect-scrollbar";
import { API_ROUTES } from "src/app/core/http/api-routes";
import { HttpService } from "src/app/core/http/http.service";
import { AuthService } from "src/app/core/services/auth.service";
import { ChangePasswordComponent } from "src/app/pages/users/change-password/change-password.component";
import swal from "sweetalert2";
declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
// export const ROUTES: RouteInfo[] = []
// {
//   path: "/dashboard",
//   title: "Dashboard",
//   type: "link",
//   icontype: "dashboard",
// },
// // Owner
// {
//   path: "/owners/owners",
//   title: "Owners",
//   type: "link",
//   icontype: "person_filled",
// },
// // Survey
// {
//   path: "/survey/survey",
//   title: "Survey",
//   type: "link",
//   icontype: "visibility",
// },
// // Lands
// {
//   path: "/lands",
//   title: "Lands",
//   type: "sub",
//   icontype: "view_quilt",
//   collapse: "lands",
//   children: [
//     { path: "add-land", title: "Add Land", ab: "-" },
//     { path: "list-lands", title: "List Lands", ab: "-" },
//   ],
// },
// // Bills
// {
//   path: "/payments",
//   title: "Payments",
//   type: "sub",
//   icontype: "attach_money",
//   collapse: "payments",
//   children: [
//     { path: "list-bills", title: "Bills", ab: "-" },
//     { path: "pay-bill", title: "Pay Bill", ab: "-" },
//     { path: "list-payment", title: "Payments", ab: "-" },
//   ],
// },
// // Reports
// {
//   path: "/reports",
//   title: "Reports",
//   type: "sub",
//   icontype: "description",
//   collapse: "reports",
//   children: [
//     { path: "owner-report", title: "Owners", ab: "-" },
//     { path: "bank-report", title: "Banks", ab: "-" },
//     { path: "bills-report", title: "Bills", ab: "-" },
//     { path: "payments-report", title: "Payment", ab: "-" },
//   ],
// },
// // Users
// {
//   path: "/settings",
//   title: "Settings",
//   type: "sub",
//   icontype: "settings",
//   collapse: "settings",
//   children: [
//     { path: "list-banks", title: "Banks", ab: "-" },
//     { path: "list-payment-methods", title: "Payment Metho", ab: "-" },
//     { path: "districts", title: "Districts", ab: "-" },
//     { path: "sub-districts", title: "Sub Districts", ab: "-" },
//     { path: "sections", title: "Sections", ab: "-" },
//     { path: "streets", title: "Streets", ab: "-" },
//     { path: "land-charges", title: "Land Charges", ab: "-" },
//     { path: "users", title: "Users", ab: "-" },
//   ],
// },
// ];
@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  public menuItems: any;
  user: any = {};
  constructor(
    private httpApi: HttpService,
    private auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) { }
  ps: any;
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  async ngOnInit() {
    this.user = this.auth.getUser(); 
    let { modules } = this.auth.user_data;
    this.menuItems = modules.map(i => {
      if (i.children) i.children = i.children.filter(c => c.type == 'child')
      return i
    });
    this.menuItems.sort((a, b) =>a.sort-b.sort )
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar .sidebar-wrapper")
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  logoutAction() {
    swal
      .fire({
        title: "Are you sure?",
        text: "Are you sure to Logout",
        icon: "error",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-secondary",
        },
        confirmButtonText: "Yes, Logout!",
        buttonsStyling: false,
      })
      .then(async (result) => {
        try {
          if (result.value) {
            this.auth.logOut();
            this.router.navigate(["/users/login"]);
          }
        } catch (error) {
          // this.auth.logOut();
          // this.router.navigate(['/users/login'])
        }
      });
  }
  changePass() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: "500px",
      hasBackdrop: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.ngOnInit();
      }
    });
  }
}
