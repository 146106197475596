import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class ToastrService {
  success(message: any, from: 'top' | 'bottom' = 'top', align: 'center' | 'right' | 'left' = 'right') {
    const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];
    if (typeof message !== 'string') {
      message = message.error || message.message
    }
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: 'success',
      timer: 1500,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  info(message: any, from: 'top' | 'bottom' = 'top', align: 'center' | 'right' | 'left' = 'right') {
    if (typeof message !== 'string') {
      message = message.error || message.message
    }
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: 'info',
      timer: 1500,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  warning(message: any, from: 'top' | 'bottom' = 'top', align: 'center' | 'right' | 'left' = 'right') {
    if (typeof message !== 'string') {
      message = message.error || message.message
    }
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: 'warning',
      timer: 1500,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  error(message: any, from: 'top' | 'center' | 'bottom' = 'top', align: 'center' | 'right' | 'left' = 'right') {
    if (typeof message !== 'string') {
      message = message.error || message.message
    }
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: 'danger',
      timer: 1500,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  rose(message: any, from: 'top' | 'bottom' = 'top', align: 'center' | 'right' | 'left' = 'right') {
    if (typeof message !== 'string') {
      message = message.error || message.message
    }
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: 'rose',
      timer: 1500,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  primary(message: any, from: 'top' | 'bottom' = 'top', align: 'center' | 'right' | 'left' = 'right') {
    const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];
    if (typeof message !== 'string') {
      message = message.error || message.message
    }
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: 'primary',
      timer: 1500,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }


  confirm(message, options: any = {
    icon: 'question', title: '',
  }) {

    if (options.isCancelOnly) {
      options.showConfirmButton = false
      options.showConfirmButton = false
      options.icon = 'error'
    }

    if (options.option1) {
      options.confirmButtonText = options.option1;
      options.showConfirmButton = true
    }

    if (options.option2) {
      options.denyButtonText = options.option2
      options.showDenyButton = true
    }


    return swal.fire({
      title: options.title,
      text: message,
      icon: options.icon || 'question',
      showCancelButton: true,
      ...options,
      customClass: {
        confirmButton: "btn-dialog btn-confirm",
        denyButton: "btn-dialog btn-deny",
        cancelButton: 'btn-dialog btn-cancel',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.isConfirmed && options.option1) return { isOption1: true };
      if (result.isDenied) return { isOption2: true };

      if (result.isConfirmed) return { isConfirmed: true };

      return { isDismissed: true };
    });
  }

}
