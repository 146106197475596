<style>
  @media print{
    footer{
      display: none;
    }
  }
</style>
<footer class="footer ">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <a href="https://easytech.so/">
            EASY TECH
          </a>
        </li>
        <li>
          <a href="https://easytech.so/about-us">
            About Us
          </a>
        </li>
        <li>
          <a href="https://easytech.so/">
            Licenses
          </a>
        </li>
      </ul>
    </nav>
    <div class="copyright pull-right">
      &copy;
      {{test | date: 'yyyy'}},
      <a href="https://easytech.so/" target="_blank">EASYTECH</a>
    </div>
  </div>
</footer>
